.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-header {
  text-align: center;
  margin-bottom: 40px;
}

.home-header h1 {
  font-size: 28px;
  color: #333;
  font-weight: bold;
}

.home-header p {
  font-size: 16px;
  color: #777;
  font-style: italic;
}

.menu {
  background-color: #222;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.menu ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin-right: 20px;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.menu li a:hover {
  color: #ccc;
}

section {
  margin-bottom: 40px;
}

section h2 {
  font-size: 28px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

section p {
  font-size: 16px;
  color: #777;
  line-height: 1.5;
}

ul {
  list-style: disc;
  padding-left: 20px;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.image-gallery img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.image-gallery img:hover {
  transform: scale(1.05);
}

footer {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 40px;
}

.service-image {
  width: 300px;
  height: 200px;
  max-width: 400px;
  border-radius: 5px;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .service img {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .service-details {
    text-align: center;
  }

  .service-details h3 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }

  .service-details p {
    font-size: 16px;
    color: #777;
    line-height: 1.5;
  }

  footer {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 40px;
  }

  .service-image {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 5px;
  }
}
