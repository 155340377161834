.contact-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.error {
  border-color: red;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.submit-btn:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .contact-form {
    max-width: 100%;
    padding: 0 20px;
  }

  input,
  textarea {
    font-size: 14px;
  }
}
