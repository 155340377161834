.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-content {
    font-size: 18px;
    line-height: 1.6;
    color: #777;
  }
  
  .about-content p {
    margin-bottom: 20px;
  }
  