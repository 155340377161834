.gallery-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
  }
  
  .gallery {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
  }
  
  .gallery-item {
    flex: 0 0 auto;
    margin: 0 5px;
    max-height: 200px;
    width: 150px;
    max-width: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  