.main-image{
    width: 100%;
    height: 100%;
}

.service-list {
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-end;
  }